import { useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

const getRowStyles = (modifier, filter, name) => {
  if (filter && name.toLowerCase().search(filter.toLowerCase()) !== -1) {
    return {
      color: "#F2C14E",
    };
  }
  if (modifier < 0) {
    return {
      color: "#B4436C",
    };
  }

  if (modifier == 0) {
    return {
      color: "#808080",
    };
  }
  return {
    color: "#5FAD56",
  };
};

const statNames = ["Str", "Int", "Wis", "Dex", "Con", "Luck"];

const RaceContentCard = (props) => {
  const { content, filter, resists, title, setSearch1, showAllRes, stats } =
    props;

  const [_stats, setStats] = useState([]);
  const [showRes, setShowRes] = useState(showAllRes);

  useMemo(() => {
    if (stats) {
      setStats(
        Object.keys(stats).map((e) => {
          return stats[e];
        })
      );
    }
  }, [stats]);
  return (
    <Card className="p-0 mb-3 h-100" style={{ backgroundColor: "black" }}>
      <Card.Header
        style={{
          fontSize: "22px",
          color: "rgb(70, 130, 180)",
          textTransform: "uppercase",
        }}
        className="fw-bolder"
      >
        {/* Title */}
        <div className="d-flex justify-content-between">
          <span>{title}</span>
          <span
            onClick={() => setShowRes(!showRes)}
            style={{ cursor: "pointer" }}
          >
            Show&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-shield-fill-plus"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m-.5 5a.5.5 0 0 1 1 0v1.5H10a.5.5 0 0 1 0 1H8.5V9a.5.5 0 0 1-1 0V7.5H6a.5.5 0 0 1 0-1h1.5z"
              />
            </svg>
          </span>
        </div>
        {/* Stats */}
        <div>
          <div className="d-flex fs-6">
            {statNames.map((e, i) => {
              return (
                <div style={{ minWidth: "45px", marginRight: "5px" }} key={i}>
                  {e}
                </div>
              );
            })}
          </div>
          <div className="d-flex fs-6">
            {_stats.map((e, i) => {
              return (
                <div style={{ minWidth: "45px", marginRight: "5px" }} key={i}>
                  {e > 0 ? "+" : ""}
                  {e}
                </div>
              );
            })}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div
          style={{ color: "rgb(174, 217, 224)" }}
          className="fs-6 d-flex flex-column h-100 justify-content-between"
        >
          <div>
            {content.map((e, i) => {
              return (
                <Row style={getRowStyles(e.modifier, filter, e.name)} key={i}>
                  <Col
                    style={{ cursor: "pointer" }}
                    onClick={() => setSearch1(e.name)}
                    className="col-9"
                  >
                    {e.name}
                  </Col>
                  <Col className="d-flex justify-content-end col-3">
                    <span className="text-right">{e.modifier}%</span>
                  </Col>
                </Row>
              );
            })}
          </div>
          <Row className={`${showAllRes || showRes ? "mt-3" : ""}`}>
            {(showAllRes || showRes) && (
              <>
                <span className="fs-4">Resists</span>
                {resists.map((e, i) => {
                  return (
                    <Col
                      className="col-4 d-flex justify-content-between"
                      key={i}
                    >
                      <span>{e.resist}</span>
                      <span
                        style={{
                          color: `${
                            e.name > 0
                              ? "green"
                              : e.name === 0
                              ? "gray"
                              : "darkred"
                          }`,
                        }}
                      >
                        {e.name}%
                      </span>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default RaceContentCard;
