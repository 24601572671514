import { Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

import RaceContentCard from "../components/common/RaceContentCard";
import { Loading } from "../components/common/Loading";
import { Title } from "../components/common/Title";

const filterByPositive = (data, search1) => {
  if (!search1) {
    return data;
  }
  return data.filter((e) => {
    return e.modifiers.some(
      (s) =>
        s.name.toLowerCase().search(search1.toLowerCase()) !== -1 &&
        Number(s.modifier) > 0
    );
  });
};

const filterByRace = (data, search2) => {
  if (!search2) {
    return data;
  }

  return data.filter((e) => {
    return search2
      .split(",")
      .filter((e) => e.trim())
      .some(
        (race) =>
          e.name.toLowerCase().trim().search(race.toLowerCase().trim()) !== -1
      );
  });
};

function RaceModifiers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [showAllRes, setShowAllRes] = useState(false);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      try {
        const data = await (
          await fetch(`${API_HOST}public/race-modifiers`)
        ).json();
        // set state when the data received
        setData(data);
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
    };

    dataFetch();
  }, []);

  return (
    <Container>
      <Title text={"Race modifiers"} />
      <Row className="mt-5">
        <Col md={3} className="mb-3">
          <Form.Label
            style={{ color: "#6197ce", fontWeight: "bold" }}
            className="text-bold"
          >
            Filter by positive
          </Form.Label>
          <Form.Control
            aria-label="Filter by positive"
            onChange={(e) => setSearch1(e.target.value)}
            value={search1}
            type="search"
          />
        </Col>
        <Col md={3} className="mb-3">
          <Form.Label
            style={{ color: "#6197ce", fontWeight: "bold" }}
            className="text-bold"
          >
            Filter by races
          </Form.Label>
          <Form.Control
            aria-label="Filter by races"
            onChange={(e) => setSearch2(e.target.value)}
            value={search2}
            type="search"
          />
          <Form.Text id="playername help" style={{ color: "#f1f4c6" }}>
            Comma separated
          </Form.Text>
        </Col>
        <Col
          className="fs-2 d-flex justify-content-end align-items-center"
          style={{ color: "rgb(241, 244, 198)", cursor: "pointer" }}
        >
          <div onClick={() => setShowAllRes(!showAllRes)}>
            <span className="me-3">Show all resists</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-shield-fill-plus"
              viewBox="0 0 16 16"
              style={{ cursor: "pointer" }}
            >
              <path
                fillRule="evenodd"
                d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m-.5 5a.5.5 0 0 1 1 0v1.5H10a.5.5 0 0 1 0 1H8.5V9a.5.5 0 0 1-1 0V7.5H6a.5.5 0 0 1 0-1h1.5z"
              />
            </svg>
          </div>
        </Col>
      </Row>
      {/* Individual stats */}
      <Row className="mt-5">
        <Loading isLoading={loading}>
          {filterByRace(filterByPositive(data, search1), search2).map(
            (e, i) => {
              return (
                <Col md={6} sm={12} key={i} className="mb-3">
                  <RaceContentCard
                    content={e.modifiers}
                    filter={search1}
                    resists={e.resists || []}
                    setSearch1={setSearch1}
                    stats={e.stats || {}}
                    title={e.name}
                    showAllRes={showAllRes}
                  />
                </Col>
              );
            }
          )}
        </Loading>
      </Row>
    </Container>
  );
}

export default RaceModifiers;
